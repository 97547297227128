(function($) {
    $.fn.collapsible = function (options) {
        if (options === 'remove') {
            $(this).each(function() {
                // Destroy
            });

            return false;
        } else if (options === 'init') {
            $(this).find('> li').each(function() {
                if ($(this).hasClass('active')) {
                    var $body = $(this).find('.collapsible-body')[0];

                    $body.style.maxHeight = $body.scrollHeight + 'px';
                }
            });

            return this;
        } else if (options === 'close') {
            $(this).find('> li').removeClass('active').find('.collapsible-body').css('max-height', 0);

            return this;
        }

        var defaults = {
        };

        options = $.extend(defaults, options);

        $(this).each(function() {
            var $collapsible = $(this);
            var $items = $collapsible.find('> li');

            if ($collapsible.hasClass('initialized'))
                return;

            $items.find('.collapsible-header').click(function() {
                var $li = $(this).parent();
                var $body = $li.find('.collapsible-body')[0];

                $li.toggleClass('active');

                if (!$collapsible.hasClass('expandable'))
                    $items.not($li).removeClass('active').find('.collapsible-body').css('max-height', 0);

                if ($li.hasClass('active'))
                    $body.style.maxHeight =  $body.scrollHeight + 'px';
                else
                    $body.style.maxHeight = null;
            });

            $items.each(function () {
                if ($(this).hasClass('active')) {
                    var $body = $(this).find('.collapsible-body')[0];

                    $body.style.maxHeight = $body.scrollHeight + 'px';
                }
            });

            $collapsible.addClass('initialized');
        });

        return this;
    }
}(jQuery));