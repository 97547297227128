if (!String.prototype.nl2br)
    String.prototype.nl2br = function () {
        return (this + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
    }

if (!String.prototype.splice) {
    String.prototype.splice = function(start, delCount, newSubStr) {
        return this.slice(0, start) + newSubStr + this.slice(start + Math.abs(delCount));
    };
}

if (!String.prototype.isEmpty) {
    String.prototype.isEmpty = function () {
        return (this.length === 0 || !this.trim());
    };
}

if (!Array.prototype.contains)
    Array.prototype.contains = function (value) {
        return this.indexOf(value) > -1;
    }

$._bodyScrollTop = $._bodyScrollLeft = $._bodyTop = $._bodyLeft = 0;

function disableBodyScroll() {
    var $body = $('body');

    $._bodyScrollTop = $(document).scrollTop();
    $._bodyScrollLeft = $(document).scrollLeft();
    $._bodyTop = $body.css('top');
    $._bodyLeft = $body.css('left');

    $body
        .addClass('noscroll')
        .css({'top': -$._bodyScrollTop, 'left': -$._bodyScrollLeft})
        .data('disable-touch', true);
}

function enableBodyScroll() {
    $('body')
        .removeClass('noscroll')
        .removeData('disable-touch')
        .css({
            top: $._bodyTop,
            left: $._bodyLeft
        });

    $(document).scrollTop($._bodyScrollTop).scrollLeft($._bodyScrollLeft);
}