(function($) {
    $.fn.dataTable = function (options) {
        var refresh = function ($table, url) {
            var filters = {};
            var _dataTable = $table[0].dataTable;
            var _currentPage = _dataTable.currentPage;
            var _pageCount = _dataTable.pageCount;
            var options = _dataTable.options;
            var $pagination = $(_dataTable.options.pagination);
            var $loading = $('<div class="loading"></div>');

            url = url || options.url + (!options.url.endsWith('/') ? '/' : '') + _currentPage;

            // Loading sign
            //$table.before($loading);

            $table.find('tbody').css('filter', 'blur(5px)');

            // Get filter input values to build filter object

            if (_dataTable.filters) {
                _dataTable.isFiltered = true;
                filters = _dataTable.filters;

                // Set column filter input values
                _dataTable.ui.filters.each(function () {
                    let $this = $(this);
                    let key = $this.data('key');

                    if (filters[key]) {
                        $this.addClass('active').val(filters[key]);

                        if (!$this.next().hasClass('datatable-filter-reset'))
                            $this.after('<span class="datatable-filter-reset" />')
                    }
                    else if ($this.removeClass('active').next().hasClass('datatable-filter-reset'))
                        $this.next().remove();
                });
            }
            else {
                _dataTable.isFiltered = false;
                _dataTable.ui.filters.each(function () {
                    /*let column = options.columns[$(this).closest('th').index()];
                    let key = typeof column === 'object' ? Object.keys(column)[0] : column;

                    if ($(this).attr('data-filterkey'))
                        key = $(this).attr('data-filterkey');*/

                    let $this = $(this);
                    let key = $this.data('key');

                    if ($(this).val()) {
                        filters[key] = $(this).val();
                        _dataTable.isFiltered = true;
                    }
                });
            }

            if (typeof options.rowCount !== 'undefined')
                filters.rowCount = options.rowCount;

            // Triggering custom event handler
            if (typeof options.onBeforeRefresh === 'function')
                options.onBeforeRefresh();

            // Call the remote API
            REST.post(url, filters)
                .complete(function() {
                    $table.find('tbody').css('filter', 'none');
                    //$loading.remove();
                })
                .success(function(data) {
                    var items = options.dataKey ? data[options.dataKey] : data;
                    var rowTemplate = '';
                    var focusedRow = $table.find('tr:focus').index();

                    _currentPage = $table[0].dataTable.currentPage = data.page;
                    _pageCount = $table[0].dataTable.pageCount = data.pageCount;

                    // Collecting column orders
                    if (typeof options.rowRenderer === 'function') {
                        rowTemplate = options.rowRenderer(_dataTable);
                    }
                    else if (Array.isArray(options.columns)) {
                        rowTemplate = '<tr>';

                        options.columns.forEach(function (col) {
                            if (typeof col === 'object') {
                                var key = Object.keys(col)[0];
                                col = "format " + key + " '" + col[key] + "'";
                            }

                            rowTemplate += '<td>{{' + col + '}}</td>';
                        });

                        rowTemplate += '</tr>';
                    }

                    // Rendering table rows
                    $table.find('tbody').html(Template.fromString(
                        '{{#each this}}' + rowTemplate + '{{else}}<td colspan="999"><p class="empty center">Nincsenek megjeleníthető sorok.</p></td>{{/each}}',
                        items
                    ));

                    $table.find('tbody tr').on('click', function(e) {
                        if (options.canSelect) {
                            if (!$(this).hasClass('empty') && ['BUTTON', 'I', 'A'].indexOf(e.target.nodeName) === -1 && ($(e.target).closest('tr')[0] === e.currentTarget || e.target === e.currentTarget) && typeof options.onSelect === 'function')
                                options.onSelect(this);
                        }
                    }).on('keydown', function(e) {
                        if (options.canSelect) {
                            switch (e.keyCode) {
                                case 40: e.preventDefault(); $(this).next().focus(); break;
                                case 38: e.preventDefault(); $(this).prev().focus(); break;
                                case 39: e.preventDefault(); $pagination.find('.next').trigger('click'); break;
                                case 37: e.preventDefault(); $pagination.find('.prev').trigger('click'); break;
                                case 13: e.preventDefault(); $(this).trigger('click'); break;
                            }
                        }
                    }).each(function() {
                        $(this).attr('tabindex', 0);
                    });

                    if (focusedRow > -1) {
                        var $rows = $table.find('tbody tr');

                        $rows.eq(Math.min(focusedRow, $rows.length - 1)).focus();
                    }

                    // Set pagination
                    $pagination.find('.first, .prev').attr('disabled', _currentPage === 1 ? 'disabled' : null);
                    $pagination.find('.next, .last').attr('disabled', _currentPage === _pageCount ? 'disabled' : null);

                    $pagination.find('a').filter(function() {
                        return $(this).hasClass('number');
                    }).remove();

                    var $e = $pagination.find('.next');
                    var $s = $pagination.find('select').empty();
                    var pFrom = 1;

                    if (_currentPage > 2)
                        pFrom = _currentPage - 2;
                    if (_currentPage + 2 >= _pageCount)
                        pFrom = Math.max(_pageCount - 4, 1);

                    for (var i = pFrom; i <= Math.min(pFrom + 4, _pageCount); ++i) {
                        $('<a href="#" class="number">' + i + '</a>')
                            .toggleClass('active', i === _currentPage)
                            .insertBefore($e);
                    }

                    for (var i = 1; i <= _pageCount; ++i)
                        $s.append('<option value="' + i + '" ' + (i === _currentPage ? 'selected' : '') + '>' + i + '</option>')

                    $pagination.removeClass('hidden');

                    // DataPanel compatibility
                    var $panel = $pagination.closest('.datapanel');
                    if ($panel.length > 0 && $panel[0].dataPanel) {
                        $pagination.width($panel[0].dataPanel.ui.main.clientWidth);
                    }

                    // Triggering custom event handler
                    if (typeof options.onRefresh === 'function')
                        options.onRefresh(data);

                    if (items.length === 1) {
                        $table.find('tbody tr').eq(0).trigger('click');
                    }
                })
                .error(function(msg) {
                    $table.find('tbody').html('<td colspan="999" class="empty center">Hiba történt az adatok lekérdezése közben.</td>').css('filter', 'none').prev().remove();
                });
        }

        if (options === 'remove') {
            $(this).each(function() {
                // Destroy
            });

            return this;
        } else if (options === 'refresh') {
            $(this).each(function() {
                refresh($(this));
            });

            return this;
        } else if (options === 'options') {
            let args = arguments;

            $(this).each(function () {
                this.dataTable.options = $.extend(this.dataTable.options, args[1]);
            });

            return this;
        } else if (options === 'filters') {
            let args = arguments;

            if (args[1]) {
                $(this).each(function () {
                    $(this)[0].dataTable.filters = args[1];

                    refresh($(this));
                });

                return this;
            }
            else
                return $(this)[0].dataTable.filters;
        } else if (options === 'clearFilters') {
            $(this).each(function() {
                $(this)[0].dataTable.filters = null;

                refresh($(this));
            });

            return this;
        }

        var defaults = {
            url: null,
            refreshOnStartup: true,
            pagination: '.pagination',
            canSelect: true,
            dataKey: '',
            columns: [],
            onFilter: null,
            onRefresh: null,
            onBeforeRefresh: null,
            onSelect: null,
        };

        options = $.extend(defaults, options);

        $(this).each(function() {
            var $table = $(this).addClass('datatable');
            var $pagination = $(options.pagination).css('width', '100vw');
            var self = this;

            this.dataTable = {
                currentPage: 1,
                pageCount: 1,
                options: options,
                isFiltered: false,
                filters: null,
                ui: {
                    filters: $table.find('.datatable-filter-input')
                }
            };

            // Filter input change
            this.dataTable.ui.filters.on('input', function() {
                var $this = $(this);

                // Reset the active timeout
                if ($this.data('timer'))
                    clearTimeout($this.data('timer'));

                if ($this.val().trim().length > 0) {
                    $this.addClass('active');

                    if (!$this.next().hasClass('datatable-filter-reset'))
                        $this.after('<span class="datatable-filter-reset" />')
                }
                else {
                    $this.removeClass('active');

                    $this.next('.datatable-filter-reset').remove()
                }

                // Starting timeout
                $this.data('timer', setTimeout(function() {
                    // Triggering custom event handler
                    if (typeof options.onFilter === 'function')
                        options.onFilter();

                    self.dataTable.currentPage = 1;
                    refresh($table);
                }, 500));
            })
                // Add reset button to pre-filled filters
                .each(function() {
                    let $this = $(this);
                    let column = options.columns[$this.closest('th').index()];
                    let key = typeof column === 'object' ? Object.keys(column)[0] : column;

                    if ($this.attr('data-filterkey'))
                        key = $this.attr('data-filterkey');

                    $this.data('key', key);

                    if ($this.val()) {
                        $this.addClass('active');
                        $this.after('<span class="datatable-filter-reset" />')
                    }
                });

            // Removing a filter
            $(document).on('click', '.datatable-filter-reset', function() {
                if (self.dataTable.filters)
                    delete self.dataTable.filters[$(this).prev().data('key')];

                $(this).fadeOut(200, function() {
                    $(this).remove();
                }).prev('.datatable-filter-input').val('').removeClass('active');

                // Triggering custom event handler
                if (typeof options.onFilter === 'function')
                    options.onFilter();

                self.dataTable.currentPage = 1;
                refresh($table);
            });

            // Pagination events
            $pagination.on('click', 'a', function(e) {
                e.preventDefault();

                var page;

                if ($(this).hasClass('prev'))
                    page = $table[0].dataTable.currentPage > 1 ? $table[0].dataTable.currentPage - 1 : $table[0].dataTable.currentPage;
                else if ($(this).hasClass('next'))
                    page = $table[0].dataTable.currentPage < $table[0].dataTable.pageCount ? $table[0].dataTable.currentPage + 1 : $table[0].dataTable.currentPage;
                else if ($(this).hasClass('first') && $table[0].dataTable.currentPage > 1)
                    page = 'first';
                else if ($(this).hasClass('last') && $table[0].dataTable.currentPage < $table[0].dataTable.pageCount)
                    page = 'last';
                else
                    page = parseInt(this.innerText, 10);

                if (page !== $table[0].dataTable.currentPage)
                    refresh($table, options.url + (!options.url.endsWith('/') ? '/' : '') + page);
            });

            $pagination.on('change', 'select', function() {
                page = parseInt($(this).val(), 10);
                refresh($table, options.url + (!options.url.endsWith('/') ? '/' : '') + page);
            });

            // DataPanel compatibility
            $(document).on('datapanel:open datapanel:resize', function(e, instance) {
                $pagination.width(instance.ui.main.clientWidth);
            }).on('datapanel:close', function() {
                $pagination.css('width', '100vw');
            });

            if (options.refreshOnStartup)
                refresh($table);
        });

        return this;
    }
}(jQuery));