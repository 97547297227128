(function($) {
    const applyMaxSize = {
        name: 'applyMaxSize',
        enabled: true,
        phase: 'beforeWrite',
        fn({ state }) {
            const {
                height: referenceHeight,
                top: referenceTop,
            } = state.elements.reference.getBoundingClientRect();

            const height =
                document.body.clientHeight
                - referenceTop
                - referenceHeight
                - state.elements.arrow.offsetHeight
                - 10;

            $(state.elements.popper)
                .find('.dropdown-content-wrapper')
                .css('max-height', `${height}px`)
                .parent().css('max-height', `${height}px`)
        }
    };

    $.fn.tooltipDropdown = function (options) {
        tippy($(this).toArray(), {
            allowHTML: true,
            interactive: true,
            trigger: 'click',
            animation: 'shift-away',
            theme: 'light',
            placement: 'bottom',
            showOnInit: true,
            arrow: true,
            sticky: true,
            content(reference) {
                return '<div class="dropdown-content-wrapper"><div class="dropdown-content">' + document.getElementById(reference.getAttribute('data-dropdown-template')).innerHTML + '</div></div>';
            },
            onShow(instance) {
                setTimeout(function() {
                    var $active = $(instance.popper).find('.active');
                    var $popper = $(instance.popper).find('.os-viewport');

                    if ($active.length > 0)
                        $popper.scrollTop($active.position().top - $active.outerHeight() / 2 - $popper.innerHeight() / 2);
                }, 0);
            },
            onCreate(instance) {
                $(instance.popper)
                    .addClass('tooltip-dropdown')
                    .find('.dropdown-content-wrapper').overlayScrollbars($.OPTIONS.scrollbars).overlayScrollbars();
            },
            popperOptions: {
                modifiers: [applyMaxSize]
            }
        });

        return this;
    }
}(jQuery));