$(function() {
    Utils.initTooltip('[data-tooltip]');
/*        $(document)
            .on('mouseenter', '[data-tooltip]', function() {
                var $this = $(this);

                $this.data('tooltip', $('<div />')
                    .addClass('tooltip')
                    .css({
                        position: 'fixed',
                        left: $this.offset().left + $this.outerWidth() / 2,
                        top: $this.offset().top,
                        zIndex: 9999
                    }).append(
                        $('<div />').addClass('tooltiptext').html($this.attr('data-tooltip'))
                    )
                );

                if ($this.data('tooltipTimer'))
                    clearInterval($this.data('tooltipTimer'));

                $this.data('tooltipTimer', setTimeout(function() {
                    $('body').append($this.data('tooltip').hide().fadeIn(200));

                    $(window).one('mousedown', function() {
                        if ($this.data('tooltipTimer'))
                            clearInterval($this.data('tooltipTimer'));

                        if ($this.data('tooltip'))
                            $this.data('tooltip').fadeOut(200, function () {
                                $(this).remove();
                            });
                    });
                }, 500));
            })
            .on('mouseleave', '[data-tooltip]', function() {
                var $this = $(this);

                if ($this.data('tooltipTimer'))
                    clearInterval($this.data('tooltipTimer'));

                if ($this.data('tooltip')) {
                    $this.data('tooltip').fadeOut(200, function () {
                        $(this).remove();
                    });
                    $this.data('tooltip', null);
                }
            });*/
})