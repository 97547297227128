$(function() {
    $(document).on('click', '.toggle-buttons li', function() {
        var $this = $(this);
        var $btn = $this.closest('.toggle-buttons');

        if ($btn.attr('disabled'))
            return;

        var prev = $btn.find('li.active').attr('data-value') || '';
        var val = $this.attr('data-value');

        $this.parent().find('li').removeClass('active');
        $this.addClass('active');

        $btn.attr('data-value', val).trigger('change', { value: val, previousValue: prev });

        // Centering
        var $parent = $btn.parent();
        $parent.animate({
            scrollLeft: $this[0].offsetLeft - $parent.width() / 2 + $this[0].offsetWidth / 2
        }, 200);
    });
});