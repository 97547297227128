(function($) {
    $.fn.dataPanel = function (options) {
        if (options === 'remove') {
            $(this).each(function() {
                // Destroy
            });

            return false;
        }
        else if (options === 'open') {
            $(this).each(function() {
                this.dataPanel.open();
            });

            return false;
        }
        else if (options === 'close') {
            $(this).each(function() {
                this.dataPanel.close();
            });

            return false;
        }

        var defaults = {
            onOpen: null,
            onClose: null,
            onResize: null,
            sizes: [65, 35],
            alwaysOpened: false
        };

        options = $.extend(defaults, options);

        $(this).each(function() {
            var _split = null;
            var self = this;
            var $this = $(this).toggleClass('slide', window.innerWidth <= 800);
            var $main = $this.find('.datapanel-main-container').css('width', '100vw');
            var $side = $this.find('.datapanel-side-container')
                .on('click', '.datapanel-btn-close', function() {
                    $this[0].dataPanel.close();
                })
                .hide();

            $(window).on('resize', function() {
                $this.toggleClass('slide', window.innerWidth <= 800);

                if (typeof options.onResize === 'function')
                    options.onResize($this[0].dataPanel);

                $(document).trigger('datapanel:resize', $this[0].dataPanel);
            });

            this.dataPanel = {
                ui: {
                    main: $main[0],
                    side: $side[0]
                },
                open: function() {
                    /*history.pushState('datapanel', document.title, location.href);
                    $(window).on('popstate.dataPanel', function(e) {
                        if (e.originalEvent.state === 'datapanel') {
                            history.pushState('datapanel', document.title, location.href);
                            self.dataPanel.close();
                        }
                    });*/

                    if ($this.hasClass('slide')) {
                        $side.show();
                    }
                    else {
                        if (!_split) {
                            $side.show();

                            _split = Split([$main[0], $side[0]], {
                                sizes: options.sizes,
                                gutterSize: 15,
                                onDrag: function () {
                                    if (typeof options.onResize === 'function')
                                        options.onResize($this[0].dataPanel);

                                    $(document).trigger('datapanel:resize', $this[0].dataPanel);
                                }
                            });

                            if (typeof options.onResize === 'function')
                                options.onResize($this[0].dataPanel);

                            $(document).trigger('datapanel:resize', $this[0].dataPanel);
                        }
                    }

                    $this.addClass('active');
                    $side.find('.datapanel-content').scrollTop(0);

                    if (typeof options.onOpen === 'function')
                        options.onOpen($this[0].dataPanel);

                    $(document).trigger('datapanel:open', $this[0].dataPanel);
                },
                close: function() {
                    /*$(window).off('popstate.dataPanel');
                    history.back();*/

                    $this.removeClass('active');

                    if (_split) {
                        _split.destroy();
                        _split = null;
                        $side.hide();
                        $main.css('width', '100vw');

                        if (typeof options.onResize === 'function')
                            options.onResize($this[0].dataPanel);

                        $(document).trigger('datapanel:resize', $this[0].dataPanel);
                    }

                    if (typeof options.onClose === 'function')
                        options.onClose($this[0].dataPanel);

                    $(document).trigger('datapanel:close', $this[0].dataPanel);
                }
            };

            if (options.alwaysOpened) {
                $(this).addClass('opened')
                if (!$(this).hasClass('slide')) {
                    this.dataPanel.open();
                }
            }
        });

        return this;
    }
}(jQuery));