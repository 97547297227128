var REST = (function () {
	return {
		'get': function(url) {
			return REST.call('GET', url);
		},
		'post': function(url, data) {
			return REST.call('POST', url, data);
		},
		'patch': function(url, data) {
			return REST.call('PATCH', url, data);
		},
		'put': function(url, data) {
			return REST.call('PUT', url, data);
		},
		'delete': function(url) {
			return REST.call('DELETE', url);
		},
		'call': function (method, url, data) {
			var restCallback = new RESTCallback();

			if (typeof data === 'object' && !(data instanceof FormData)) {
				var fData = new FormData();

				for (var key in data)
					if (data.hasOwnProperty(key))
						fData.append(key, data[key]);

				data = fData;
			}

			restCallback.xhr = $.ajax({
				type:        method,
				async:       true,
				processData: false,
				url:         (url.indexOf('/api/') !== 0 ? '/api/' : '/') + url.replace(/^\//, ''),
				data:        data,
				dataType:    'json',
				cache:       false,
				contentType: false,
				timeout: 	 30000,
			})
				.fail(function (xhr, textStatus, errorThrown) {
					var data = {
						result: {
							code: xhr.status,
							message: textStatus
						}
					};

					switch (textStatus) {
						case 'timeout':
							Toast.error('Időtúllépés történt.', 408);
							restCallback.trigger('complete', data);
							break;
						case 'error':
							data.result.code = 400;
							data.result.message = 'Hiba történt a szerverrel való kommunikáció közben.';
							restCallback.trigger('error', data);
							break;
						default:
							restCallback.trigger('error', data);
							break;
					}
				})
				.done(function (data) {
					if (data.result.code > 0) {
						switch (data.result.code) {
							case 401:
								alert('A folytatáshoz be kell jelentkeznie!');
								location.href = '/login';
								break;
							default:
								restCallback.trigger('error', data);
								break;
						}
					}
					else
						restCallback.trigger('success', data);

					return this;
				});

			return restCallback;
		}
	}
})();

var RESTCallback = function() {
	var _success = [];
	var _error = [];
	var _complete = [];

	return {
		success: function(callback) {
			_success.push(callback);

			return this;
		},
		error: function(callback) {
			_error.push(callback);

			return this;
		},
		complete: function(callback) {
			_complete.push(callback);

			return this;
		},
		trigger: function(eventName, data) {
			_complete.forEach(function(c) {
				if (typeof c === 'function')
					c(data);
			});

			if (eventName === 'success')
				_success.forEach(function(c) {
					if (typeof c === 'function')
						c(data);
				});

			if (eventName === 'error')
				_error.forEach(function(c) {
					if (typeof c === 'function')
						c(data);
				});
		},
		xhr: null
	}
};