function SwipeEvent(element, options) {
    var _xDown = null;
    var _yDown = null;
    var _element = element;
    var _isMouseDown = false;
    var _options = Object.assign({
        triggerPercent: 0.3,
        mouseEnabled: false
    }, options || {});
    var _this = this;
    var evtMap = {
        left: [],
        up: [],
        down: [],
        right: []
    };

    function handleTouchStart(evt) {
        _xDown = evt.touches[0].clientX;
        _yDown = evt.touches[0].clientY;
    }

    function handleMouseDown(evt) {
        if (!_options.mouseEnabled)
            return;

        _xDown = evt.clientX;
        _yDown = evt.clientY;
        _isMouseDown = true;
    }

    function handleMouseUp(evt) {
        if (!_isMouseDown) return;

        var deltaX = evt.clientX - _xDown;
        var deltaY = evt.clientY - _yDown;
        var distMoved = Math.abs(Math.abs(deltaX) > Math.abs(deltaY) ? deltaX : deltaY);
        var activePct = distMoved / _element.offsetWidth;

        if (activePct > _options.triggerPercent) {
            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                deltaX < 0 ? _this.trigger('left', _element) : _this.trigger('right', _element);
            } else {
                deltaY > 0 ? _this.trigger('up', _element) : _this.trigger('down', _element);
            }
        }
    }

    function handleTouchEnd(evt) {
        var deltaX = evt.changedTouches[0].clientX - _xDown;
        var deltaY = evt.changedTouches[0].clientY - _yDown;
        var distMoved = Math.abs(Math.abs(deltaX) > Math.abs(deltaY) ? deltaX : deltaY);
        var activePct = distMoved / _element.offsetWidth;

        if (activePct > _options.triggerPercent) {
            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                deltaX < 0 ? _this.trigger('left', _element) : _this.trigger('right', _element);
            } else {
                deltaY > 0 ? _this.trigger('up', _element) : _this.trigger('down', _element);
            }
        }
    }

    this.trigger = function (evt, data) {
        evtMap[evt].map(function (handler) {
            handler(data);
        });

        return this;
    }

    this.on = function (evt, cb) {
        evtMap[evt].push(cb);

        return this;
    }

    this.off = function (evt, lcb) {
        evtMap[evt] = evtMap[evt].filter(function (cb) {
            return cb !== lcb;
        });

        return this;
    }

    _element.addEventListener('touchstart', handleTouchStart);
    _element.addEventListener('touchend', handleTouchEnd);
    _element.addEventListener('mousedown', handleMouseDown);
    _element.addEventListener('mouseup', handleMouseUp);
}