var Template = (function () {
	var _globals = {};
	var _templateCache = {};
	var _helpers = {
        'template':    function (name, parameters) {
            for (var key in parameters.hash)
                if (parameters.hash.hasOwnProperty(key))
                    this[key] = parameters.hash[key];

            return new Handlebars.SafeString(Template.render(name, this));
        },
		'each-reverse': function(context, options) {
			var ret = '', data;

			if (options.data)
				data = Handlebars.createFrame(options.data);

			if (context) {
				if (Array.isArray(context)) {
					for (var i = context.length - 1; i >= 0; --i) {
						if (data)
							data.index = i;

						ret += options.fn(context[i], {data: data});
					}
				}
				else {
					var props = Object.keys(context).reverse();

					props.forEach(function(i, key) {
						data.key = i;
						ret += options.fn(context[i], {data: data});
					});
				}
			} else {
				ret = options.inverse(this);
			}

			return ret;
		},
		'reverse': function(arr) {
			arr.reverse();
		},
		'set': function(name, value) {
        	_globals[name] = value;
		},
		'get': function(name) {
			return _globals[name];
		},
		'inc': function(variable) {
			return parseInt(variable, 10) + 1;
		},
		'dec': function(variable) {
			return parseInt(variable, 10) - 1;
		},
		'add': function (a, b) {
        	return parseInt(a, 10) + parseInt(b, 10);
		},
		'default': function(variable, defaultValue) {
			return variable || defaultValue;
		},
		'round': function(value) {
			return Math.round(value);
		},
		'floor': function(value) {
			return Math.floor(value);
		},
        'count': function(value) {
        	if (Array.isArray(value))
            	return value.length;

        	return Object.keys(value).length;
        },
		'sum': function(value, key) {
			var sum = 0;

			value.forEach(function(i) {
				if (typeof key === 'undefined')
					sum += i;
				else
					sum += i[key];
			});

			return sum;
		},
		'ifequals': function(a, b, options) {
			if(a == b)
				return options.fn(this);

			return options.inverse(this);
		},
		'ifnotequals': function(a, b, options) {
			if(a !== b)
				return options.fn(this);

			return options.inverse(this);
		},
		'format': function(value, param1, param2) {
			if (typeof value === 'undefined')
				return;

			function format(value, param1, param2) {
				if (typeof value === 'undefined')
					return '';

                switch (param1) {
                    case 'thousand':
                        return Number(value).toLocaleString();
                    case 'lowercase':
                        return value.toLowerCase();
                    case 'uppercase':
                        return value.toUpperCase();
					case 'capitalize':
						return value.charAt(0).toUpperCase() + value.slice(1);
                    case 'nlbr':
                        return value ? new Handlebars.SafeString(Format.nl2br(value)) : '';
                    case 'date':
                    	var date = value;

						if (typeof value === 'object')
							date = (new Date(Date.UTC(value.year, value.month - 1, value.day)).getTime() + value.hour * 3600000) / 1000;
						else if (typeof value === 'string')
							date = new Date(value).getTime() / 1000;

                        var utc = typeof param2.hash.utc === 'undefined' ? true : param2.hash.utc;
                        var format = typeof param2.hash.format === 'undefined' ? 'yyyy. mm. dd.' : param2.hash.format;

                        date = parseInt(date, 10);

                        if (isNaN(date))
                        	return value;

                        return dateFormat(date, format, utc);
                    case 'datetime':
                    	if (typeof value === 'object')
                    		value = (new Date(Date.UTC(value.year, value.month - 1, value.day)).getTime() + value.hour * 3600000) / 1000;
						else if (typeof value === 'string')
							value = new Date(value.replace(' ', 'T') + 'Z').getTime() / 1000;

                        var utc = typeof param2.hash.utc === 'undefined' ? true : param2.hash.utc;
                        var format = typeof param2.hash.format === 'undefined' ? 'yyyy. mm. dd. HH:MM:ss' : param2.hash.format;

                        if (typeof param2.hash.short !== 'undefined')
                        	format = 'mmm. dd. HH:MM';

                        return dateFormat(parseInt(value, 10), format, utc);
                    case 'floor':
                        return Math.floor(value);
                    case 'ceil':
                        return Math.ceil(value);
					case 'boolean':
						return value ? 'igen' : 'nem';
					case 'occasion':
						return Format.lengthToText(value);
					case 'subjectType':
						return Format.subjectTypeToText(value);
					case 'feeStatement':
						return Format.feeStatementToText(value);
					default:
						return value;
                }
            }

            if (Array.isArray(value)) {
			    var values = value.slice();

			    for (var i = 0, c = values.length; i < c; ++i) {
			        values[i] = format(values[i], param1, param2);
                }

                return values;
            }

			return format(value, param1, param2);
		},
		'join': function(items, block) {
			var delimiter = ', ', start = 0, len = items ? items.length : 0, end = len;

			if (typeof block === 'object') {
				delimiter = block.hash.delimiter || '';
				start = block.hash.start || 0;
				len = items ? items.length : 0;
				end = block.hash.end || len;
			}
			else if (typeof block === 'string')
				delimiter = block;

			var out = '';

			if (end > len)
				end = len;

			if (typeof block === 'function') {
				for (var i = start; i < end; ++i) {
					if (i > start)
						out += delimiter;

					if (typeof items[i] === 'string')
						out += items[i];
					else
						out += block(items[i]);
				}

				return out;
			}
			else
				return [].concat(items).slice(start, end).join(delimiter);
		},
		'concat': function() {
			var result = '';

			for (var arg in arguments) {
				if (arguments.hasOwnProperty(arg) && typeof arguments[arg] !== 'object') {
					result += arguments[arg];
				}
			}

			return result;
		},
		'for': function(from,to,incr,block) {
			var accum='';
			for(var i = from; i<=to; i+=incr) {
				accum+=block.fn(i);
			}
			return accum;
		},
		'compare': function (lvalue, operator, rvalue, options) {

			var operators, result;

			if (arguments.length < 3) {
				throw new Error("Handlerbars Helper 'compare' needs 2 parameters");
			}

			if (options === undefined) {
				options = rvalue;
				rvalue = operator;
				operator = "===";
			}

			operators = {
				'==':     function (l, r) {
					return l == r;
				},
				'===':    function (l, r) {
					return l === r;
				},
				'!=':     function (l, r) {
					return l != r;
				},
				'!==':    function (l, r) {
					return l !== r;
				},
				'<':      function (l, r) {
					return l < r;
				},
				'>':      function (l, r) {
					return l > r;
				},
				'<=':     function (l, r) {
					return l <= r;
				},
				'>=':     function (l, r) {
					return l >= r;
				},
				'typeof': function (l, r) {
					return typeof l === r;
				}
			};

			if (!operators[operator]) {
				throw new Error("Handlerbars Helper 'compare' doesn't know the operator " + operator);
			}

			result = operators[operator](lvalue, rvalue);

			if (result) {
				return options.fn(this);
			} else {
				return options.inverse(this);
			}
		},
		'ifIn': function(elem, list, options) {
			if (list && list.indexOf(elem) > -1)
				return options.fn(this);

			return options.inverse(this);
		},
		'substr': function(value, start, end) {
            return Number.isInteger(end)
				? String(value).substr(start, end)
				: String(value).substr(start);
        },
		'escape': function(value, type) {
			switch (type) {
				case 'quotes': default:
					return Utils.escapeQuotes(value);
			}
		},
	};

	// Egyedi segédfüggvények beregisztrálása
	for (var helper in _helpers)
		if (_helpers.hasOwnProperty(helper))
			Handlebars.registerHelper(helper, _helpers[helper]);

	return {
		/**
		 * Sablon megjelenítése
		 *
		 * @param {string} name Sablon neve
		 * @param {array} data Adatok
		 * @returns string
		 */
		render: function (name, data) {
            if (!_templateCache[name]) {
                var content = $('#' + name).html();

                _templateCache[name] = Handlebars.compile(content);
            }

			if (typeof data === 'undefined')
				data = {};
			
			data.COMMON = $.COMMON;

            return _templateCache[name](data);
		},

        /**
         * Sablon megjelenítése
         *
         * @param {string} content Sablon tartalma
         * @param {array} data Adatok
         * @returns string
         */
        fromString: function (content, data) {
            return (Handlebars.compile(content))(data);
        },

        /**
         * Távoli sablon betöltése
         *
         * @param {string} name Sablon neve
         * @return RESTCallback
         */
		/*load: function (name) {
            if (!_templateCache[name]) {
                return REST.get('template/' + name)
                    .success(function(data) {
                        console.log('JUHU');
                        _templateCache[name] = Handlebars.compile(data.template);
                    });
            }
            else {
                var restCallback = new RESTCallback();

                setTimeout(function() {
                    restCallback.trigger('success');
                }, 100);

                return restCallback;
            }
        }*/
	}
})();