(function($) {
    $.fn.tabs = function (options) {
        if (options === 'remove') {
            $(this).each(function () {
                // Destroy
            });

            return false;
        } else if (options === 'selectTab') {
            $(this).find('li.tab[data-tab="' + arguments[1] + '"]').trigger('click');

            return false;
        } else if (options === 'getSelectedTab') {
            return $(this).find('li.tab[data-tab].active').attr('data-tab');
        }

        var defaults = {
        };

        options = $.extend(defaults, options);

        //$(this).wrapAll('<div class="tabs-wrapper" />').each(function() {
        $(this).each(function() {
            var $this = $(this);
            var $tabs = $this.find('li.tab');
            var _tabs = $tabs;
            var _prevTabContent = null;
            var _prevTab = null;

            $this.find('.btn-first').click(function (e) {
                $tabs.eq(0).trigger('click');
            });
            $this.find('.btn-last').click(function (e) {
                $tabs.eq($tabs.length - 1).trigger('click');
            });
            $this.find('.btn-prev').click(function (e) {
                $tabs.filter('.active').prev().trigger('click');
            });
            $this.find('.btn-next').click(function (e) {
                $tabs.filter('.active').next().trigger('click');
            });

            $tabs.each(function() {
                var $tab = $(this);
                var $tabContent = $('#' + $tab.attr('data-tab')).data('tabIndex', _tabs.index($tab));

                if (options.selected) {
                    if ($tab.attr('data-tab') === options.selected) {
                        $tab.addClass('active');
                        $tabContent.addClass('active');
                    }
                }

                // Swipe events
                /*(new SwipeEvent($tabContent[0]))
                    .on('left', function(e) {
                        var i = $(e).data('tabIndex');

                        if (i < _tabs.length - 1) {
                            $tabs.eq(i + 1).trigger('click');
                        }
                    })
                    .on('right', function(e) {
                        var i = $(e).data('tabIndex');

                        if (i > 0) {
                            $tabs.eq(i - 1).trigger('click');
                        }
                    });*/

            }).click(function (e, silent) {
                var $tab = $(this);
                //var $parent = $this.closest('.tabs-wrapper');
                var $parent = $this.closest('.tabs');
                var _right = _prevTabContent && _prevTab.index() > $tab.index();

                if ($tab.is(_prevTab))
                    return;

                if (_prevTabContent)
                    _prevTabContent.removeClass('animate active from-right');

                $tabs.removeClass('active').css('background-color', '');

                $tab.addClass('active').css('background-color', $tab.attr('data-color') ? $tab.attr('data-color') : '');

                // Centering tab
                $parent.animate({
                    scrollLeft: -$parent.position().left + $tab[0].offsetLeft - $parent.width() / 2 + $tab[0].offsetWidth / 2
                }, 200);

                _prevTabContent = $('#' + $tab.attr('data-tab'));

                if (_right) {
                    _prevTabContent.addClass(!silent ? 'from-right' : '').redraw();
                }

                //setTimeout(function() {
                    _prevTabContent.addClass('active' + (!silent ? ' animate' : ''));
                //}, 0);

                $this.trigger('tab.selected', $tab);
                $this.trigger('change', { selected: $tab, previous: _prevTab });

                _prevTab = $tab;
            }).filter('.active').trigger('click', true);

            if ($tabs.filter('.active').length === 0)
                $tabs.first().trigger('click', true);

            $this.parent().trigger('load');
        });

        return this;
    }
}(jQuery));