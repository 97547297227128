(function($) {
    $.fn.autoHeight = function (options) {
        if (options === 'remove') {
            $(this).each(function() {
                // Destroy
            });

            return false;
        }

        var defaults = {
            minRows: 2,
            maxRows: 10
        };

        options = $.extend(defaults, options);

        $(this).each(function() {
            var $this = $(this);

            options.minRows = $this.data('minRows') || options.minRows;
            options.maxRows = $this.data('maxRows') || options.maxRows;

            if (options.maxRows < options.minRows)
                options.minRows = options.maxRows;

            $this.on('input change', function() {
                if (this.scrollTop === 0)
                    this.scrollTop = 1;

                while (this.scrollTop === 0) {
                    if (this.rows > options.minRows)
                        this.rows--;
                    else
                        break;

                    this.scrollTop = 1;

                    if (options.maxRows && this.rows < options.maxRows)
                        this.style.overflowY = 'hidden';

                    if (this.scrollTop > 0) {
                        this.rows++;
                        break;
                    }
                }
                while (this.scrollTop > 0) {
                    if (options.maxRows === null || this.rows < options.maxRows) {
                        this.rows++;

                        if (this.scrollTop === 0)
                            this.scrollTop = 1;

                    }
                    else {
                        this.style.overflowY = 'auto';
                        break;
                    }
                }
            }).css({
                resize: 'none',
                overflow: 'hidden'
            }).trigger('change');
        });

        return this;
    }
}(jQuery));