var Dialog = (function () {
	return {
		input: function(title) {
			var _callback;
			var $dlg = $('#dlgInput');
			var inputResult = {
				ok: function(callback) {
					_callback = callback;
				}
			};

			$dlg.find('.dlg-input-title').text(title);
			$dlg.find('.inputfield').val('');

			Dialog.show($dlg)
				.ok(function() {
					if (typeof _callback === 'function') {
						_callback($dlg.find('.inputfield').val());
					}

					_callback = null;
					inputResult = null;
				});

			return inputResult;
		},
		show: function($dialog, options) {
			var _callback, _cancelCallback;
			var $dlg = $($dialog);
			var $form = $dlg.find('form');
			var result = {
				ok: function(callback) {
					_callback = callback;
					return this;
				},
				cancel: function(callback) {
					_cancelCallback = callback;
					return this;
				}
			};
			var handler = function(e) {
				Dialog.close($dlg);

				if ($(e.target).hasClass('dlg-modal-ok') && typeof _callback === 'function')
					_callback($dlg[0]);
				else if (typeof _cancelCallback === 'function')
					_cancelCallback($dlg[0]);

				_callback = null;
				_cancelCallback = null;
				result = null;
			};
			var checkValidity = function() {
				$dlg.find('.dlg-modal-ok').prop('disabled', !$form[0].checkValidity());
			};

			var defaults = {
				dismissible: false,
				autoSubmit: false
			};

			options = $.extend(defaults, options);

			disableBodyScroll();

			if ($form.length > 0) {
				$form.find('input, select, textarea').on('input change blur', function () {
					checkValidity();
				});
				checkValidity();
			}

			$dlg.fadeIn(200).css('display', 'flex').addClass('active').find('.dlg-modal-close').one('click', handler);
			$dlg.find('input')
				.on('keypress.dialog', function(e) {
					if (options.autoSubmit && e.keyCode === 13)
						$($dialog).find('.dlg-modal-ok').not(':disabled').trigger('click');
				});

			if (options.dismissible) {
				$dlg.on('click.dialog', function(e) {
					if (e.target === $dlg[0]) {
						Dialog.close($dlg);
						_callback = null;
						result = null;
					}
				})
			}

			$(document).on('keydown.dialog', function(e) {
				if (e.keyCode === 27 && options.dismissible) {
					e.preventDefault();
					Dialog.close($dlg);

					if (typeof _cancelCallback === 'function')
						_cancelCallback($dlg[0]);

					_cancelCallback = null;
					_callback = null;
					result = null;
				}
			});

			$(document).trigger('dialog.shown', $dlg);

			/*history.pushState('bla', document.title, location.href);
			$(window).on('popstate.dialog', function(e) {
				console.log(e.originalEvent)
				//if (e.originalEvent.state === 'mydialog') {
					history.pushState('dialog', document.title, location.href);
					Dialog.close($dlg);
					_callback = null;
					result = null
				//}
			});*/

			return result;
		},
		close: function($dialog) {
			$($dialog).find('input').off('keypress.dialog');
			$(document).off('keydown.dialog');
			/*$(window).off('popstate.dialog');
			history.back();*/

			$($dialog)
				.off('click.dialog')
				.removeClass('active')
				.fadeOut(200, function() {
					enableBodyScroll();
				});

			$(document).trigger('dialog.closed', $($dialog));
		}
	}
})();