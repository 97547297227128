// Dropdown
$(document).on('click', '.dropdown, .split-button', function(e) {
    var $this = $(this).toggleClass('active');

    if ($this.hasClass('active')) {
        $this.find('.dropdown-content').scrollTop(0)

        $('body').one('click', function (e) {
            if (e.target !== $this[0]) {
                $this.removeClass('active');
            }
        });
    }
}).on('click', '.dropdown-content > li:not(.divider):not(.opt)', function(e) {
    e.stopImmediatePropagation();
    $(this).closest('.dropdown-content').parent().removeClass('active');
});