(function($) {
	$.fn.uploadImage = function (options) {
		function showPreview(input) {
			var url = input.value;
			var ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();

			function initCroppie() {
				if (!$previewImage.data('croppie')) {
					$previewImage.croppie({
						enableExif: true,
						viewport: {
							width: options.width,
							height: options.height,
							type: options.type
						},
						boundary: {
							width: parseInt(options.width, 10) + 30,
							height: parseInt(options.height, 10) + 30
						}
					});
					$previewImage.parent().append($cropperToolbar);
				}
			}


			if (input.files && input.files[0] && (ext === 'gif' || ext === 'png' || ext === 'jpeg' || ext === 'jpg')) {
				var reader = new FileReader();

				reader.onload = function (e) {
					$previewImage.attr('src', e.target.result);

					initCroppie();
				};

				reader.readAsDataURL(input.files[0]);
			}
			else {
				$previewImage.attr('src', options.placeholder);
				initCroppie();
			}
		}

		var defaults = {
			width: 256,
			height: 256,
			placeholder: '/img/no_preview.png',
			uploadURL: '',
			uploadButton: '',
			deleteButton: '',
			postName: 'image',
			type: 'rectangle'
		};

		options = $.extend(defaults, options);

		var $cropperToolbar = $('<div class="center" id="cropperToolbar"><button type="button" class="btn-flat" id="btnCropperApply"><i class="icon-glyph">done</i> Alkalmaz</button><button type="button" class="btn-flat" id="btnCropperCancel"><i class="icon-glyph">clear</i> Mégse</button></div>');
		var $previewImage = $(this);
		var original = $(this).attr('src');
		var $deleteButton = $(options.deleteButton);
		var $uploadButton = $(options.uploadButton).click(function() {
			var $this = $(this);
			var $file = $('<input type="file" accept=".jpeg,.jpg,.gif,.png" style="display: none;" />')
				.change(function() {
					$this.hide();
					$deleteButton.hide();
					$cropperToolbar.fadeIn(200);

					showPreview(this, $previewImage);

					$file.off().remove();
				});
			$('body').append($file);
			$file.click();
		});

		$cropperToolbar.find('#btnCropperApply').click(function() {
			$previewImage.croppie('result', {type: 'blob', circle: false}).then(function (blob) {
				var img = URL.createObjectURL(blob);

				$previewImage.trigger('change', blob);
				$cropperToolbar.hide();
				$uploadButton.fadeIn(200);
				$deleteButton.fadeIn(200);
				$previewImage.attr('src', img).croppie('destroy').removeData('croppie');

				/*var data = new FormData();

				data.append(options.postName, blob);

				REST.post(options.uploadURL, data)
					.complete(function () {
					})
					.success(function (data) {
						Toast.show(data.result.message);

						$previewImage.trigger('uploaded', data);

						$cropperToolbar.hide();
						$uploadButton.fadeIn(200);
						$deleteButton.fadeIn(200);
						$previewImage.attr('src', img).croppie('destroy').removeData('croppie');
					})
					.error(function (data) {
						Toast.error(data.result.message);
					});*/
			});
		});

		$cropperToolbar.find('#btnCropperCancel').click(function() {
			$previewImage
				.croppie('destroy').removeData('croppie')
				.attr('src', original);
			$cropperToolbar.hide();
			$uploadButton.fadeIn(200);
			$deleteButton.fadeIn(200);
		});

		return this;
	}
}(jQuery));