(function($) {
    $.fn.userInfo = function (options) {
        if (options === 'remove') {
            $(this).each(function () {
                $(this).off('mouseenter mouseleave');
            });

            return false;
        }

        var defaults = {
        };

        options = $.extend(defaults, options);

        $(this).each(function() {
            var $this = $(this);
            var userId = $this.data('id');
            var $card = $('<div class="user-info-card">Teszt</div>')
                .css({
                    position: 'fixed',
                    zIndex: 999
                });

            $this.hover(
                function (e) {
                    if ($this.data('timer'))
                        clearTimeout($this.data('timer'));

                    if (!$card.is(':visible')) {
                        $this.data('timer', setTimeout(function () {
                            $card.empty().html('<div class="loading"></div>');

                            REST.get('/api/user/' + userId)
                                .success(function(data) {
                                    $card.empty().html(
                                        '<div class="user-info-header">' +
                                        '  <div class="avatar left medium">' +
                                        '    <div style="background: ' + data.user.color + ';">' + data.user.monogram + '</div>\n' +
                                        '  </div>' +
                                        '  <div class="right">' +
                                        '    <h2 class="user-info-name">' + data.user.name + '</h2>' +
                                        '    <div class="user-info-secondary">' + data.user.job + '</div>' +
                                        '  </div>' +
                                        '</div>' +
                                        '<div class="user-info-buttons">' +
                                        (data.user.phone ? '  <a href="tel:' + data.user.phone + '" target="_blank" class="btn-flat" title="' + data.user.phone + '"><i class="icon-glyph">call</i></a>' : '') +
                                        '  <a href="mailto:' + data.user.email + '" target="_blank" class="btn-flat" title="' + data.user.email + '"><i class="icon-glyph">mail</i></a>' +
                                        '</div>'
                                    );
                                })
                                .error(function(data) {
                                    $card.empty().text(data.result.message);
                                });

                            $this.append($card.hide());
                            $card.fadeIn(200);

                            $card.css({
                                top: e.originalEvent.pageY + 'px',
                                left: e.originalEvent.pageX + 'px'
                            });
                        }, 1000));
                    }
                },

                function (e) {
                    if ($this.data('timer'))
                        clearTimeout($this.data('timer'));

                    if ($card.is(':visible')) {
                        $this.data('timer', setTimeout(function () {
                            $card.fadeOut(200, function () {
                                $card.remove()
                            });
                        }, 750));
                    }
                }
            );
        });

        return this;
    }
}(jQuery));