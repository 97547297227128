let Transition = {
    morphIn: (source, target) => {
        return new Promise((ready, reject) => {
            const $this = $(source);
            const $el = $(target);
            const fromRect = $this[0].getBoundingClientRect();
            const toRect = $el[0].getBoundingClientRect();

            const x = fromRect.left - toRect.left;
            const y = fromRect.top - toRect.top;
            const scaleX = fromRect.width / toRect.width;
            const scaleY = fromRect.height / toRect.height;

            $el
                .css({
                    zIndex: 2,
                    visibility: 'hidden',
                    transition: 'none',
                    transform: 'translate(' + x + 'px, ' + y + 'px) scale(' + scaleX + ', ' + scaleY + ')'
                });

            setTimeout(() => {
                $this.css('visibility', 'hidden');

                $el
                    .one('transitionend', () => {
                        $el.css({
                            transform: 'none'
                        });
                        ready();
                    })
                    .css({
                        visibility: 'visible',
                        transformOrigin: 'top left',
                        transition: 'all 0.4s',
                        transform: 'translate(0, 0) scale(1, 1)',
                    });
            }, 0);
        });
    },

    morphOut: (source, target) => {
        return new Promise((ready, reject) => {
            const $this = $(source);
            const $el = $(target);
            const fromRect = $this[0].getBoundingClientRect();
            const toRect = $el[0].getBoundingClientRect();

            const x = fromRect.left - toRect.left;
            const y = fromRect.top - toRect.top;
            const scaleX = fromRect.width / toRect.width;
            const scaleY = fromRect.height / toRect.height;

            $el.css({
                transform: 'translate(' + x + 'px, ' + y + 'px) scale(' + scaleX + ', ' + scaleY + ')',
            }).one('transitionend', () => {
                $el.css({
                    transition: 'none',
                    transform: 'none',
                    visibility: 'hidden'
                });
                $this.css('visibility', 'visible');
                ready();
            });
        });
    }
};