var Utils = (function () {
    return {
        SELECT2_OPTIONS: {
            width: '100%',
            dropdownParent: 'body',
            dropdownAutoWidth: true,
            language: {
                noResults: function() {
                    return '<div class="empty">Nincs találat.</div>';
                }
            },
            escapeMarkup: function (markup) {
                return markup;
            },
        },

        disableAutoComplete: function() {
            $('.autocomplete').each(function() {
                if ($(this).autocomplete())
                    $(this).autocomplete().disable();
                $(this).data('autocomplete-disabled', true);
            });
        },

        enableAutoComplete: function() {
            $('.autocomplete').each(function() {
                if ($(this).autocomplete())
                    $(this).autocomplete().enable();
                $(this).removeData('autocomplete-disabled');
            });
        },

        highlightText: function (text, highlight) {
            highlight = highlight
                .trim()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase();

            if (!highlight)
                return text;

            var _normal = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
            var i = _normal.indexOf(highlight);

            return text.splice(i + highlight.length, 0, '</strong>').splice(i, 0, '<strong>')
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/&lt;(\/?strong)&gt;/g, '<$1>');
        },
        updateStickyColumns: function() {
            var $cols = $('.sticky-col').css({position: 'static', left: ''});

            $('.fixed-column').scrollLeft(0).css('opacity', 0);

            setTimeout(function() {
                $cols.each(function () {
                    $(this).css({position: '', left: $(this).position().left});
                });

                $('.fixed-column').css('opacity', 1);
            }, 100);
        },

        initSelects: function(settings, selector) {
            var _select2Options = $.extend({}, Utils.SELECT2_OPTIONS, settings);

            $(selector || 'select').each(function() {
                var $this = $(this);
                var options = {};
                var $placeHolder = $this.find('option[value=""]');

                $this.next('label').addClass('active');

                if ($placeHolder.length > 0) {
                    options.allowClear = !$placeHolder.is(':disabled');
                    options.placeholder = '';
                }

                switch ($this.data('select2-matcher')) {
                    case 'beginning':
                        options.matcher = function (params, data) {
                            if ($.trim(params.term) === '')
                                return data;

                            return data.text.toUpperCase().indexOf(params.term.toUpperCase()) === 0 ? data : null;
                        };
                        break;
                }

                $this.select2($.extend({}, _select2Options, options));
            });
        },

        initTooltip: function(selector) {
            $(selector).each(function() {
                tippy(this, {
                    content: $(this).data('tooltip'),
                    allowHTML: true,
                    delay: [300, 30],
                    duration: 100,
                    animation: 'shift-away'
                });
            });
        },

        escapeQuotes: function(text) {
            //return text ? text.replace(/[\""]/g, '\\"') : text;
            return text ? text.replace(/(['"])/g, '\\$1') : text;
        },

        openWindowWithPost: function(url, data) {
            var form = document.createElement('form');
            form.target = '_blank';
            form.method = 'POST';
            form.action = url;
            form.style.display = 'none';

            for (var key in data) {
                var input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = data[key];
                form.appendChild(input);
            }

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        }
    }
})();