var App = (function () {
    var _instance = {
        meta: function(name, content) {
            if (typeof content === 'undefined')
                return $("meta[name='" + name + "']").attr('content') || '';
            else {
                var $meta = $("meta[name='" + name + "']");

                if ($meta.length === 0) {
                    $meta = $('<meta />').attr('name', name);
                    $('head').append($meta);
                }

                $meta.attr('content', content);

                return this;
            }
        }
    };

    _instance.isArchiveMode = parseInt(_instance.meta('app:archive'), 10) === 1;

    return _instance;
})();