var Format = (function () {
    return {
        lengthToText: function(length) {
            var v = parseInt(length, 10);

            return (v === 1 ? 'Szimpla'
                : (v === 2 ? 'Dupla'
                    : (v === 3 ? 'Tripla'
                        : (v === 4 ? 'Négy'
                                : v
                        )))) + ' óra';
        },
        nl2br: function(text) {
            return text.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
        },
        nl2space: function(text) {
            return text.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1 $2');
        },
        subjectTypeToText: function(type) {
            type = parseInt(type, 10);

            return (type === 1 ? 'Elsődleges főtantárgy'
                : (type === 2 ? 'Főtantárgy'
                : 'Kiegészítő tantárgy'
                ));
        },
        feeStatementToText: function(value) {
            value = parseInt(value, 10);

            return (value === 1 ? 'Más művészeti iskola képzésében nem vesz részt.'
                : (value === 2 ? 'Állami támogatással és térítési díj fizetése mellett jelentkezik jelen intézménybe.'
                    : (value === 3 ? 'Tandíjfizetési kötelezettség mellett jelentkezik jelen intézménybe.'
                        : 'Nem jelentkezik jelen intézménybe.'
                )));
        }
    };
})();