(function($) {
    $.fn.chips = function (options) {
        if (options === 'remove') {
            $(this).each(function() {
                var _context = this.chips;

                this.chips = null;
            });
            return this;
        }
        else if (options === 'clear') {
            $(this).each(function() {
                this.chips.clear();
            });
            return this;
        }

        var defaults = {
            vertical: false,
            autocomplete: {}
        };

        options = $.extend(defaults, options);

        var _instances = [];

        $(this).each(function() {
            if (this.chips) {
                _instances.push(this.chips);
                return;
            }

            var _timer;
            var $this = $(this);
            var _items = [];
            var _context = {
                ui: {
                    container: this,
                    input: $('<input type="text" />')[0],
                    clear: $('<div class="chips-clear"></div>').hide()[0]
                },
                options: options,
                addItem: function(item) {
                    if (!Array.isArray(item))
                        item = [ item ];

                    if (item.length === 0)
                        return;

                    var that = this;

                    item.forEach(function(i) {
                        var $chip = $('<div></div>').addClass('chip').html('<div>' + (typeof i === 'string' ? i : i.value) + '</div>')
                            .attr('tabindex', 0)
                            .data('item', i)
                            .click(function() {
                                $(this).remove();

                                _items = $(that.ui.container).find('.chip').map(function() { return $(this).data('item'); }).toArray();

                                if (_items.length === 0)
                                    $(that.ui.clear).fadeOut(200);

                                $(that.ui.input).focus();
                            })
                            .on('keyup', function(e) {
                                if (e.keyCode === 8 || e.keyCode === 46)
                                    $(this).trigger('click');
                            });

                        $chip.find('> div').click(function(e) {
                            e.stopImmediatePropagation();
                        });

                        $(that.ui.clear).fadeIn(200);
                        $(that.ui.input).before($chip).trigger('blur');
                    });

                    $(that.ui.container).next('label').addClass('active');

                    _items = $(that.ui.container).find('.chip').map(function() { return $(this).data('item'); }).toArray();

                    return this;
                },
                getItems: function() {
                    return _items;
                },
                clear: function() {
                    $(this.ui.container).find('.chip').remove();
                    $(this.ui.input).trigger('blur');
                    $(this.ui.clear).hide();

                    _items = [];

                    return this;
                }
            };

            if (options.vertical)
                $this.addClass('vertical');

            $(this).click(function(e) {
                if (e.currentTarget === e.target)
                    $(_context.ui.input).focus();
            });

            $this.append(_context.ui.input);
            $this.append($(_context.ui.clear).click(function() {
                $(this).fadeOut(200);
                _context.clear();
                $(_context.ui.input).focus();
            }));

            $(_context.ui.input)
                .on('keydown', function() {
                    var that = this;

                    if (_timer)
                        clearTimeout(_timer);

                    _timer = setTimeout(function() {
                        $(that).data('empty', $(that).val().length === 0);
                    }, 100);
                })
                .on('keyup', function(e) {
                    var $this = $(this);

                    if ($this.data('empty') && e.keyCode === 8) {
                        $this.prev().focus();
                    }
                })
                .on('input', function() {
                    $(this).width(25).width(this.scrollWidth);
                })
                .on('focus', function() {
                    $(_context.ui.container).next('label').addClass('active');
                    $this.addClass('active');
                })
                .on('blur', function() {
                    $this.removeClass('active');
                    $(this).val('');

                    if ($(_context.ui.container).find('.chip').length === 0)
                        $(_context.ui.container).next('label').removeClass('active');
                })
                .autocomplete($.extend({
                    autoSelectFirst: true,
                    maxHeight: 250,
                    minChars: 1,
                    forceFixPosition: true,
                    showNoSuggestionNotice: true,
                    noSuggestionNotice: 'Nincs találat.',
                    width: 'flex',
                    orientation: 'auto',
                    preserveInput: true,
                    lookupFilter: function (suggestion, originalQuery, queryLowerCase) {
                        return suggestion.value && suggestion.value.toLowerCase().indexOf(queryLowerCase) === 0;
                    },
                    onSelect: function (suggestion) {
                        $(_context.ui.input).val('');
                        _context.addItem(suggestion);
                        $(_context.ui.input).focus();
                    }
                }, options.autocomplete));

                this.chips =_context;
            });

        if (_instances.length > 0) {
            if (_instances.length === 1)
                return _instances[0];

            return _instances;
        }

        return this;
    }
}(jQuery));