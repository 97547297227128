var Popup = (function () {
    var $popup;
    var $popupContent;

    $(document).on('click', '.popup-content .btn-close', function() {
        Popup.close();
    });

    return {
        show: function(element) {
            var $el = $(element);
            var left = $el.offset().left + $el.outerWidth();
            var top = $el.offset().top - 20;

            $popup = $('.popup').fadeIn(50);
            $popupContent = $('.popup-content');

            if (left + $popupContent.outerWidth() > window.innerWidth || $el.offset().left > window.innerWidth / 2) {
                left = $el.offset().left - $popupContent.outerWidth() - 10;
                $popupContent.removeClass('right').addClass('left');
            }
            else
                $popupContent.removeClass('left').addClass('right');

            if (top + $popupContent.outerHeight() > window.innerHeight || $el.offset().top > window.innerHeight / 2) {
                top = $el.offset().top - $popupContent.outerHeight() + 30;
                $popupContent.removeClass('top').addClass('bottom');
            }
            else
                $popupContent.removeClass('bottom').addClass('top');

            $popup.css({
                left: left + 'px',
                top: top + 'px'
            });

            if (!$popup.hasClass('active'))
                setTimeout(() => $popup.addClass('active'), 10);

            $('body').on('click.popup', function(e) {
                if ($(e.target).closest('.popup').length > 0)
                    return;

                $popupContent.find('.btn-close').trigger('click');
            });
        },

        close: function() {
            $('body').off('click.popup');
            $popup.fadeOut(150).removeClass('active');
        }
    };
})();