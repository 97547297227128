var Download = (function () {
    return {
        file: function(url, uuid) {
            return new Promise(function (resort, reject) {
                var _cookie = 'downloaded-' + uuid;
                var _url = url + (url.indexOf('?') > -1 ? '&' : '?') + 'uuid=' + encodeURIComponent(uuid);

                var $iframe = $('<iframe width="0" height="0" style="border: none; margin: 0;"></iframe>')
                    .appendTo('body')
                    .attr('src', _url);

                var check = setInterval(function() {
                    if (document.cookie.indexOf(_cookie) > -1) {
                        clearInterval(check);
                        $iframe.remove();
                        resort(uuid);
                    }
                }, 100);
            });
        },

        withoutUUID: function(url) {
            return new Promise(function (resort) {
                var $iframe = $('#hiddenDownloader');

                if ($iframe.length === 0)
                    $iframe = $('<iframe width="0" height="0" style="border: none; margin: 0;" id="hiddenDownloader"></iframe>').appendTo('body');

                $iframe.attr('src', url);

                setTimeout(function() {
                    //$iframe.remove();
                    resort();
                }, 5000);
            });
        }
    };
})();