var Toast = (function () {
    var $toastContainer = $('<div></div>').addClass('toast-container');

    $(function() {
        $('body').append($toastContainer);
        $(document).on('click', '.btn-close-toast', function() {
            var $toast = $(this).closest('.toast');

            if ($toast.timer)
                clearInterval($toast.timer);

            $toast.removeClass('active');
            setTimeout(function() {
                $toast.remove();
                $toast = null;
            }, 200);
        });
    });

    return {
        show: function (text, type) {
            var $toast = $('<div>' +
                '<div class="toast-content">' + text + '</div>' +
                '<button type="button" class="btn-close-toast"><i class="icon-glyph">close</i></button>' +
                '<div class="progress"><div class="determinate" style="width: 0;"></div></div>' +
                '</div>').addClass('toast');
            var delay = 5000;
            var $progress = $toast.find('.progress .determinate');

            switch (type) {
                case 'error':
                    $toast.addClass('error');
                    delay = 0;
                    break;
            }

            $toastContainer.prepend($toast);

            setTimeout(function() {
                $toast.addClass('active');
            }, 1);

            if (delay) {
                $toast.delay = delay;
                $toast.counter = 0;
                $toast.timer = setInterval(function () {
                    $progress.css('width', ($toast.counter / $toast.delay * 100) + '%');

                    if ($toast.counter >= $toast.delay) {
                        clearInterval($toast.timer);

                        if ($toast.hasClass('active'))
                            $toast.find('.btn-close-toast').trigger('click');
                    }

                    $toast.counter += 100;
                }, 100);
            }
            else
                $progress.parent().remove();
        },

        error: function (text, code) {
            Toast.show(
                '<i class="icon-glyph left toast-icon">error_outline</i> <span class="toast-text">' + text + '</span>'
                + (typeof code !== 'undefined' ? '<div class="code">(#' + code + ')</div>' : ''),
                'error'
            );
        }
    }
})();