(function($) {
    $.fn.collapsibleTable = function (options) {
        if (options === 'remove') {
            $(this).each(function() {
                $(this).find('.collapsible-table-content').off('change.collapsible');
                $(this).find('> tbody > tr:not(.collapsible-table-body)').off('click.collapsible');
            });

            return false;
        } else if (options === 'init') {
            $(this).find('> tbody > tr.collapsible-table-body').each(function() {
                if ($(this).hasClass('active')) {
                    this.style.maxHeight = this.scrollHeight + 'px';
                }
            });

            return this;
        } else if (options === 'close') {
            $(this).find('> tr.collapsible-table-body').removeClass('active').css('max-height', 0);

            return this;
        }

        var defaults = {
        };

        options = $.extend(defaults, options);

        $(this).each(function() {
            var $collapsible = $(this);
            var $items = $(this).find('> tbody > tr.collapsible-table-body');

            $items.find('.collapsible-table-content').on('change.collapsible', function() {
                $(this).css('max-height', $(this).closest('tr').prev().hasClass('active') ? this.scrollHeight + 'px' : '0px');
            });

            $(this).find('> tbody > tr:not(.collapsible-table-body)').on('click.collapsible', function(e) {
                if (['BUTTON', 'I', 'A'].indexOf(e.target.nodeName) !== -1 || ($(e.target).closest('tr')[0] !== e.currentTarget && e.target !== e.currentTarget))
                    return;

                var $body = $(this).next('.collapsible-table-body').find('.collapsible-table-content');

                $(this).toggleClass('active');

                if (!$collapsible.hasClass('expandable')) {
                    $items.prev().not(this).removeClass('active');
                    $items.find('.collapsible-table-content').css('max-height', 0);
                }

                if ($(this).hasClass('active')) {
                    $collapsible.trigger('beforeopen', {
                        ui: {
                            row: this,
                            content: $body[0]
                        }
                    });
                }

                $body.trigger('change');

                if ($(this).hasClass('active')) {
                    $collapsible.trigger('open', {
                        ui: {
                            row: this,
                            content: $body[0]
                        }
                    });
                }
            });

            /*$items.each(function () {
                var $body = $(this).next('.collapsible-table-body');

                if ($(this).hasClass('active')) {
                    this.style.maxHeight = this.scrollHeight + 'px';
                }
            });*/
        });

        return this;
    }
}(jQuery));