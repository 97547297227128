(function($) {
	$.fn.stickyHeader = function (options) {
        if (options === 'remove') {
            $(this).each(function() {
                var _context = this.stickyHeader;

                if (_context) {
                    $(_context.options.scrollContainer).off('scroll', _context.onscroll);
                    $(window).off('resize', _context.onwindowresize);
                    $(_context.fixedHeader).remove();

                    $('body').removeClass('sticky-header-active')
                    $(this).removeData('stickyHeader');
                    this.stickyHeader = null;
                }
            });

            return false;
        } else if (options === 'updateOffset') {
            var newOffset = arguments[1];

            $(this).each(function() {
                if (typeof this.stickyHeader !== 'undefined')
                    this.stickyHeader.updateOffset(newOffset)
            });

            return false;
        } else if (options === 'update') {
            $(this).each(function() {
                if (typeof this.stickyHeader !== 'undefined')
                    this.stickyHeader.update();
            });

            return false;
        }

		var defaults = {
			offset: 0,
            scrollContainer: 'parent',
            extraClass: ''
		};

		options = $.extend(defaults, options);

		$(this).each(function() {
		    if (this.stickyHeader)
		        return;

			var $table = $(this);
            var $tableHeader = $table.find('> thead');
            var parentOffset = 0;
            var _context = {
                ui: {
                    table: this,
                    header: $tableHeader[0],
                    clone: null
                },
                options: $.extend({}, options),
                updateOffset: function(newOffset) {
                    this.options.offset = newOffset || this.options.offset;

                    if (this.fixedHeader)
                        this.fixedHeader.css('top', this.options.offset);

                    this.onscroll();
                },
                update: function() {
                    this.onwindowresize();
                }
            };

            this.stickyHeader =_context;

			var parent = $table.parent();
			while (parent) {
			    try {
                    parentOffset += parent.offset().top;
                    parent = parent.parent();
                }
                catch(e) {
                    parent = null;
                }
            }

            if ($(options.scrollContainer).parent().length === 0)
                parentOffset = 0;

            _context.fixedHeader = null;
            _context.onscroll = function(e) {
                var offset = $(this).scrollTop()  - parentOffset;
                var tableOffset = $table.offset().top - options.offset
                    //+ ($(e.currentTarget).parent().length > 0 ? offset : 0);
                    + ($(e ? e.currentTarget : options.scrollContainer).parent().length > 0 ? offset : 0);

                if (offset >= tableOffset && offset <= tableOffset + $table.outerHeight(true) - $tableHeader.outerHeight(true) && !$table.is(':hidden')) {
                    if (!_context.fixedHeader) {
                        var $fixedHeader = $table.clone().empty().css({
                            position: 'fixed',
                            //top: parentOffset + options.offset) + 'px',
                            top: _context.options.offset + 'px',
                            display: 'none',
                        }).addClass('sticky-header-fixed ' + _context.options.extraClass).insertAfter($table)

                        $(_context.ui.header).find('th').each(function () {
                            $(this).data('oStyle', $(this).prop('style'));
                        });

                        _context.ui.clone = $tableHeader.clone()/*.css('visibility', 'collapse')*/.eq(0);

                        $fixedHeader.append($tableHeader);
                        $table.prepend(_context.ui.clone);

                        $fixedHeader.show();
                        _context.fixedHeader = $fixedHeader;

                        $('body').addClass('sticky-header-active');

                        _context.onwindowresize();
                    }
                }
                else {
                    if (_context.fixedHeader) {
                        $(_context.ui.clone).remove();
                        _context.ui.clone = null;

                        $table.prepend(_context.ui.header);

                        _context.fixedHeader.remove();
                        _context.fixedHeader = null;

                        $(_context.ui.header).find('th').each(function () {
                            $(this).prop('style', $(this).data('oStyle'));
                        });

                        $('body').removeClass('sticky-header-active');
                    }
                }

                if (_context.fixedHeader) {
                    _context.fixedHeader.css('left', $table.offset().left - $table.scrollLeft());
                }
            };
            _context.onwindowresize = function() {
                if (this.fixedHeader) {
                    var _this = this;

                    $(_this.fixedHeader).find('th').each(function (index) {
                        $(this).css('width', $(_this.ui.clone).find('th:eq(' + index + ')').outerWidth(true));
                    });
                    $(this.fixedHeader).width($(this.ui.table).width());

                    $(this.scrollParent).trigger('scroll');
                }
            };

            var _scrollParent = options.scrollContainer

            if (options.scrollContainer === 'parent') {
                _scrollParent = $(this).parent();

                while (_scrollParent.length > 0 && _scrollParent[0] !== document && _scrollParent.css('overflow-y') !== 'auto' && _scrollParent.css('overflow-y') !== 'scroll') {
                    _scrollParent = _scrollParent.parent();
                }

                if (_scrollParent.length === 0)
                    _scrollParent = window;
            }
            else
                _scrollParent = $(options.scrollContainer || window);

            _context.scrollParent = _scrollParent;

			$(_scrollParent).on('scroll', _context.onscroll).scroll();
			$(window).on('resize', _context.onwindowresize);

			// Datapanel compatibility
			$(document).on('datapanel:resize', function() {
                _context.onwindowresize();
            });

            $(this).trigger('load');
		});
	}
}(jQuery));